import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Prompt, RouteComponentProps } from "react-router-dom";
import AppModuleHeader from "../../common/AppModuleHeader";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { getCommandBarProps, getEnvironmentConfigTypeSettings, getEnvironmentSettings } from "./ConfigItemEdit.helper";
import Editor from "@monaco-editor/react";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { Icon } from "@fluentui/react/lib/Icon";
import { Toggle } from "@fluentui/react/lib/Toggle";
import AlertModal from "../../common/AlertModal";
import { IState } from "../../../reducers/interfaces";
import { errorType } from "../../interfaces";
import { getUserId } from "../../common/helper";
import { appInsights } from "../../../app/telemetryHelper";
import { actionCreator as appActionCreator } from "../../../app/duck";
import { actionCreator } from "../../duck";
import classNames from "./ConfigItemEdit.module.scss";
import { leftNavUrls, liveOnlyLinks } from "../../../app/LeftNav.helper";
import { getInvalidStagingConfigError } from "./helper";
import {
  ConfigSchemaValidator as SchemaValidator,
  configSchema as schema,
  getErrorsFromSchema as convertErrorsToText,
} from "./JsonValidator";
import { getDebounceMethod, parseJsonSafe } from "../../../shared/utilities/miscHelper";
import { Dictionary, IEnvironment } from "../../../app/interfaces";
import { ICommandBarItemProps } from "@fluentui/react";
import ConfigHistory, { ConfigHistoryMode } from "./ConfigHistory";
import { IConfigHistory } from "./ConfigHistory.helper";
import { ConfigItemType, deployConfigItem, startControlRun } from "../../../api/radarApi";
import { ReactElement } from "react-markdown/lib/react-markdown";
import { AppContext, IAppState } from "../../../app/App";
import ConfirmModal from "../../common/ConfirmModal";
import { useBrowserState } from "../../../shared/utilities/hooks";

export interface MatchParams {
  configItemId?: string;
  copyFromId?: string;
}

export interface IConfigItemEditProps
  extends IConfigItemEditStateProps,
    IConfigItemEditDispatchProps,
    RouteComponentProps<MatchParams> {
  configItemId?: string;
  configItemName?: string;
  configItemType?: ConfigItemType;
  configItem?: any;
  defaultConfigItem?: any;
  leftNavUrl?: string;
  appInsightsPageName?: string;
  pivotItems?: JSX.Element[];
  pivotItemsAfter?: JSX.Element[];
  getFieldErrors?: () => object;
  onConfigSelect?: (environment: string) => void;
  jsonEditorToolbar?: JSX.Element;
  appState?: IAppState;
}

export interface IConfigItemEditStateProps {
  loading?: boolean;
  saving?: boolean;
  savingSuccess?: boolean;
  configValue?: string;
  configValueOriginal?: string;
  configHistory?: IConfigHistory[];
  editingConfigValues?: Dictionary<any>;
  editingConfigOriginalValues?: Dictionary<any>;
  isSmallScreen?: boolean;
  isSuperAdmin?: boolean;
  userId?: string;
  error?: string;
  isStaging?: boolean;
  isProduction?: boolean;
  reduxEnvironments?: IEnvironment[];
}

export interface IConfigItemEditDispatchProps {
  selectLeftNavLinkByUrl?: (url: string) => void;
  createConfigItem?: (defaultItem, userId: string) => void;
  editConfigItem?: (configValue: string, environment?: string) => void;
  loadConfigItem?: (
    configItemId: string,
    environment?: string,
    refreshData?: boolean,
    doNotUpdateConfigValue?: boolean,
    callback?: (configItem: any) => void
  ) => void;
  updateConfigItem?: (configItem, environment?: string) => void;
  duplicateConfigItem?: (configItemType: ConfigItemType, configItemId: string) => void;
  updateAndPublishConfigItem?: (
    configItemType: ConfigItemType,
    configItem,
    logData: object,
    environment?: string
  ) => void;
}

export const ConfigItemEdit = (props: IConfigItemEditProps) => {
  let appModuleContainer: HTMLElement;
  const editorRef = useRef(null);

  const {
    match,
    userId,
    appInsightsPageName,
    defaultConfigItem,
    loading,
    saving,
    savingSuccess,
    configValue,
    configValueOriginal,
    error,
    configItemName,
    configItemType,
    pivotItems,
    pivotItemsAfter,
    isStaging,
    isProduction,
    leftNavUrl,
    jsonEditorToolbar,
    isSuperAdmin,
    history,
    reduxEnvironments,
    loadConfigItem,
    createConfigItem,
    selectLeftNavLinkByUrl,
  } = props;

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showDuplicateConfirmModal, setShowDuplicateConfirmModal] = useState<boolean>(false);
  const [showDeployConfirmModal, setShowDeployConfirmModal] = useState<boolean>(false);
  const [deploying, setDeploying] = useState<boolean>(false);
  const [deploySuccess, setDeploySuccess] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string | JSX.Element>();
  const [parseError, setParseError] = useState<boolean>(false);
  const [schemaErrors, setSchemaErrors] = useState<string>();
  const [enableUiValidation, setEnableUiValidation] = useState<boolean>(true);
  const [isSavePublish, setIsSavePublish] = useState<boolean>(false);
  const [controlRunModalContent, setControlRunModalContent] = useState<ReactElement>();
  const { appState, changeAppState } = useContext(AppContext);
  const { featureFlags, environments, selectedEnvironment } = appState || {};
  const configItemId = props.configItemId || (match && match.params && match.params.configItemId);
  const saveSuccessful = !loading && !error && !saving && savingSuccess;
  const deploySuccessful = !loading && !error && !deploying && deploySuccess;
  const isCreatingNewConfigItem = configItemId === "new" || !configItemId;
  const [selectedPivotKey, setSelectedPivotKey] = useBrowserState(
    `${configItemType}-${configItemId}-configItemEditPivotKey`
  );

  const reportError = (fieldErrors): boolean => {
    let errorsCount = fieldErrors && Object.keys(fieldErrors).length;

    if (errorsCount) {
      let errorPrefix = errorsCount === 1 ? "One of the fields has" : "More than one field have",
        errorWord = "error" + (errorsCount > 1 ? "s" : "");

      setErrorText(
        <div>
          <div>
            {errorPrefix} {errorWord}. Please fix the {errorWord} before saving again.
          </div>
          <div className={classNames.errorDetails}>
            {Object.keys(fieldErrors).map((errorField) => (
              <div key={errorField} className={classNames.errorDetailsItem}>
                {errorField}: {fieldErrors[errorField]}
              </div>
            ))}
          </div>
        </div>
      );
      setShowErrorModal(true);
      return true;
    } else {
      return false;
    }
  };

  const onEditorMount = useCallback((editor) => {
    editorRef.current = editor;
    editor.focus();
  }, []);

  const onPivotLinkClick = useCallback(
    (item: PivotItem) => {
      let selectedPivotKey = item.props.itemKey;
      setSelectedPivotKey(selectedPivotKey);
    },
    [setSelectedPivotKey]
  );

  const validateConfig = (newConfigItem?: any, value?: string) => {
    const { editConfigItem } = props;
    const newParseError = !newConfigItem;
    const validationErrors = newParseError ? [] : SchemaValidator.validate(newConfigItem, schema)?.errors;
    const errorText = validationErrors?.length ? convertErrorsToText(validationErrors) : "";

    editConfigItem(value);

    if (errorText !== schemaErrors || newParseError !== parseError) {
      if (errorText !== schemaErrors) {
        setSchemaErrors(errorText);
      }
      if (newParseError !== parseError) {
        setParseError(newParseError);
      }
    }
  };

  const validateConfigDebounce = getDebounceMethod(validateConfig, 250);

  const onEditorChange = (value: string) => {
    const { configItem } = props;

    var newConfigItem = parseJsonSafe(value);
    if (newConfigItem && configItem?.id && newConfigItem?.id !== configItem?.id) {
      setShowErrorModal(true);
      setErrorText(
        "The id field cannot be modified.  If you need to create a new item, click the Create button from the listing page."
      );
      return;
    }
    validateConfigDebounce(newConfigItem, value);
  };

  const onSaveChanges = (_ev, _item, savePublish: boolean = false) => {
    const { updateConfigItem, updateAndPublishConfigItem, editConfigItem, getFieldErrors } = props;

    setIsSavePublish(savePublish);

    let editorValue = editorRef.current?.getValue();

    let newValue = editorValue || configValue,
      processedConfig = null;

    if (newValue === configValueOriginal) {
      setErrorText("No changes are made to this config definition.");
      setShowErrorModal(true);
      return;
    }

    try {
      processedConfig = JSON.parse(newValue);

      editConfigItem(JSON.stringify(processedConfig, null, 2));
    } catch {}

    if (!processedConfig) {
      setErrorText("Bad JSON content are detected.  Please correct them before saving the changes.");
      setShowErrorModal(true);
      return;
    }

    const commitConfigChanges = () => {
      appModuleContainer && (appModuleContainer.scrollTop = 0);

      processedConfig["environment"] = selectedEnvironment;

      // Call Radar API to save changes
      if (savePublish) {
        updateAndPublishConfigItem(configItemType, processedConfig, {}, selectedEnvironment);
      } else {
        updateConfigItem(processedConfig, selectedEnvironment);
      }
    };

    if (enableUiValidation) {
      let fieldErrors: any = getFieldErrors();
      const reportErrors = (fieldErrors) => reportError(fieldErrors);
      if (fieldErrors?.then) {
        // Resolve if fieldErrors is a promise
        fieldErrors.then((errors) => {
          if (!reportErrors(errors)) {
            commitConfigChanges();
          }
        });
      } else {
        if (!reportErrors(fieldErrors)) {
          commitConfigChanges();
        }
      }
    } else {
      commitConfigChanges();
    }
  };

  const onSavePublishChanges = () => onSaveChanges(undefined, undefined, true);

  const onControlRun = () => {
    if (configItemId) {
      setControlRunModalContent(<Spinner label="Starting control run..." />);

      startControlRun(configItemId)
        .then(() =>
          setControlRunModalContent(<div className={classNames.info}>Control run on demand has started.</div>)
        )
        .catch((error) => setControlRunModalContent(<div className={classNames.error}>{error}</div>));
    }
  };

  const onControlView = () => {
    if (configItemId) {
      window.open(`/#${leftNavUrls.all.control}/${configItemId}`, "_blank");
    }
  };

  const onCancelChanges = () => history.push(leftNavUrl);

  const onRefreshData = () => {
    if (configItemId) {
      loadConfigItem(configItemId, selectedEnvironment, true);
    }
  };

  const onDuplicateItem = () => {
    if (configItemId && !showDuplicateConfirmModal) {
      setShowDuplicateConfirmModal(true);
    }
  };

  const onDuplicateCommit = () => {
    const { configItemType, duplicateConfigItem } = props;
    duplicateConfigItem(configItemType, configItemId);
    setShowDuplicateConfirmModal(false);
  };

  const onDeployConfig = () => {
    if (!showDeployConfirmModal) {
      setShowDeployConfirmModal(true);
    }
  };

  const onDeployCommit = () => {
    setDeploySuccess(false);
    setDeploying(true);

    deployConfigItem(configItemType, configItemId, selectedEnvironment)
      .then(() => setDeploySuccess(true))
      .catch((error) => {
        setErrorText(error);
        setShowErrorModal(true);
      })
      .finally(() => setDeploying(false));

    setShowDeployConfirmModal(false);
  };

  const onBreadcrumbClick = () => onCancelChanges();

  const onEnableUiValidationToggleChange = (event, checked?: boolean) => {
    setEnableUiValidation(checked);
  };

  const onEnvironmentChange = (environment: string) => {
    if (isDirty) {
      setErrorText(
        "There are unsaved changes for the current environment.  Please click Save to preserve the changes, or click Refresh to revert them before switching to a different environment."
      );
      setShowErrorModal(true);
      return;
    }

    changeAppState({ selectedEnvironment: environment });
  };

  const onResize = useCallback(() => {
    editorRef.current?.layout && editorRef.current?.layout();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onResize);

    return window.removeEventListener("resize", onResize);
  }, [onResize]);

  useEffect(() => {
    appInsights.trackPageView({ name: appInsightsPageName });
  }, [appInsightsPageName]);

  useEffect(() => {
    selectLeftNavLinkByUrl(leftNavUrl);
  }, [leftNavUrl, selectLeftNavLinkByUrl]);

  useEffect(() => {
    setDeploySuccess(false);

    isCreatingNewConfigItem
      ? createConfigItem(defaultConfigItem, userId)
      : loadConfigItem(configItemId, selectedEnvironment, true);
  }, [
    isCreatingNewConfigItem,
    configItemId,
    defaultConfigItem,
    userId,
    selectedEnvironment,
    loadConfigItem,
    createConfigItem,
  ]);

  const enableUiValidationToggle = (
    <Toggle
      key="UiValidationToggle"
      label="UI Validation"
      offText="Off"
      onText="On"
      inlineLabel
      defaultChecked={enableUiValidation}
      onChange={onEnableUiValidationToggleChange}
      className={classNames.rightToolsArea}
    />
  );

  const actionText = isStaging ? "Edit" : "View",
    headerText = isCreatingNewConfigItem
      ? `Create New ${configItemName}`
      : `${actionText} ${configItemName} #` + configItemId,
    breadcrumbItems = [{ text: `${headerText} (${selectedEnvironment})`, key: "" }];

  const isDirty = configValue !== configValueOriginal;

  const environmentSettings = getEnvironmentSettings(environments, selectedEnvironment);
  const environmentConfigTypeSettings = getEnvironmentConfigTypeSettings(environments, configItemType);
  const isNonGlobalSupportForThisConfigType = !!environmentConfigTypeSettings;

  const environmentSelection: ICommandBarItemProps = isStaging &&
    !isCreatingNewConfigItem &&
    featureFlags?.enableNonGlobalConfig &&
    isNonGlobalSupportForThisConfigType &&
    reduxEnvironments?.length &&
    environments?.length && {
      key: "environment",
      name: "Environment: " + selectedEnvironment,
      iconProps: {
        iconName: "Cloud",
      },
      subMenuProps: {
        items: environments
          .filter((environment: IEnvironment) => environment.name !== "NonGlobalCommon")
          .map((environment: IEnvironment) => {
            const envName = environment.name === "Prod" && !isProduction ? "Int" : environment.name;
            return {
              key: envName,
              name: envName,
              canCheck: true,
              checked: selectedEnvironment?.toLowerCase() === envName?.toLowerCase(),
              onClick: () => onEnvironmentChange(envName),
            };
          }),
      },
    };

  if (isStaging && liveOnlyLinks.indexOf(leftNavUrl) >= 0) {
    return getInvalidStagingConfigError(classNames, configItemName);
  }

  return (
    <div className={classNames.appModuleContent} ref={(element) => (appModuleContainer = element)}>
      <Prompt when={isDirty} message="You have unsaved changes, are you sure you want to leave?" />
      <AppModuleHeader
        className={classNames.appModuleHeader}
        breadcrumbItems={breadcrumbItems}
        onBreadcrumbClick={onBreadcrumbClick}
        commandBarProps={getCommandBarProps(
          { ...props, configValue: configValue, configValueOriginal, selectedEnvironment, deploying },
          onSaveChanges,
          onCancelChanges,
          onRefreshData,
          onDuplicateItem,
          onSavePublishChanges,
          onControlRun,
          onControlView,
          environmentSettings?.deploymentSettings?.method && onDeployConfig,
          environmentSelection && [environmentSelection]
        )}
        commandBarContent={
          (saving || deploying) && (
            <Spinner size={SpinnerSize.small} title={`${saving ? "Saving" : "Deploying"} data...`} />
          )
        }
      />
      {loading && (
        <Spinner
          styles={{ root: classNames.spinner, circle: classNames.spinnerCircle, label: classNames.spinnerLabel }}
          label="Loading data..."
        />
      )}
      {(saveSuccessful || deploySuccessful) && (
        <div className={classNames.successMessage}>
          <Icon iconName="SkypeCircleCheck" className={classNames.successIcon} />
          {deploySuccessful
            ? `This item has been deployed to ${selectedEnvironment} successfully.`
            : isSavePublish
            ? "Changes are saved and published to live successfully."
            : "Changes are saved successfully."}
        </div>
      )}
      {parseError && <div className={classNames.errorMsg}>Syntax Error: JSON failed to parse</div>}
      {!loading && error && <div className={classNames.error}>{error}</div>}
      {!loading && controlRunModalContent && (
        <AlertModal onCommit={() => setControlRunModalContent(undefined)}>{controlRunModalContent}</AlertModal>
      )}
      {!loading && (
        <Pivot
          className={`${classNames.pivot}, ${classNames.pivotSection}`}
          linkSize={"large"}
          selectedKey={selectedPivotKey}
          onLinkClick={onPivotLinkClick}
        >
          {pivotItems}
          <PivotItem itemKey="JsonEditor" className={classNames.pivotItem} headerText="JSON Editor">
            <div className={classNames.upperTabStrip}>
              <div className={classNames.rightToolsArea}>{jsonEditorToolbar}</div>
              <div className={classNames.extraSpacingRightToolsArea}>
                {isSuperAdmin ? enableUiValidationToggle : null}
              </div>
            </div>
            <div className={parseError || saveSuccessful ? classNames.editorWithMessage : classNames.editor}>
              <Editor language="json" value={configValue} onMount={onEditorMount} onChange={onEditorChange} />
            </div>
          </PivotItem>
          <PivotItem itemKey="history" className={classNames.pivotItem} headerText="History">
            <ConfigHistory
              configItemId={configItemId || props.configItem?.id}
              configItemType={configItemType || configItemName?.replace(" ", "")}
              configHistoryMode={ConfigHistoryMode.full}
            />
          </PivotItem>
          {pivotItemsAfter}
        </Pivot>
      )}
      {!loading && schemaErrors && (
        <div className={classNames.errorMsg} dangerouslySetInnerHTML={{ __html: schemaErrors }} />
      )}
      {showErrorModal && <AlertModal content={errorText} title="Warning" onCommit={() => setShowErrorModal(false)} />}
      {showDuplicateConfirmModal && (
        <ConfirmModal
          content={`Are you sure you want to create duplicate for this ${configItemType} item?`}
          onCommit={() => onDuplicateCommit()}
          onCancel={() => setShowDuplicateConfirmModal(false)}
        />
      )}
      {showDeployConfirmModal && (
        <ConfirmModal
          content={`Are you sure you want to deploy this ${configItemType} item to ${selectedEnvironment}?`}
          onCommit={() => onDeployCommit()}
          onCancel={() => setShowDeployConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default ConfigItemEdit;

export const mapStateToProps = (state: IState): IConfigItemEditStateProps => ({
  userId: getUserId(state.app.login_user),
  isSmallScreen: state.app.is_small_screen,
  isSuperAdmin: state.app.login_user_info && state.app.login_user_info.isSuperAdmin,
  isStaging: state.app.is_staging,
  isProduction: state.app.is_production,
  configValue: state.modules.editing_config_value,
  configValueOriginal: state.modules.editing_config_value_original,
  loading: state.modules.loading_config,
  saving: state.modules.saving_config,
  savingSuccess: state.modules.saving_config_success,
  error: state.modules.errors[errorType.config],
  editingConfigValues: state.modules.editing_config_values,
  editingConfigOriginalValues: state.modules.editing_config_original_values,
  configHistory: state.modules.config_history,
  reduxEnvironments: state.modules.environments,
});

export const mapDispatchToProps: IConfigItemEditDispatchProps = {
  selectLeftNavLinkByUrl: appActionCreator.selectLeftNavLinkByUrl,
  createConfigItem: actionCreator.createConfigItem,
  editConfigItem: actionCreator.editConfigItem,
  duplicateConfigItem: actionCreator.duplicateConfigItem,
  updateAndPublishConfigItem: actionCreator.updateAndPublishConfigItem,
};
