import React from "react";
import { getControlSdkOutputFields } from "./MyControlEdit.helper";
import classNames from "./ControlSdkDesigner.module.scss";
import { IControlSdkDesignerProps } from "./ControlSdkDesigner";
import ConfigItemEditForm from "../common/ConfigItemEditForm";

export interface IControlSdkOutputEditorProps extends IControlSdkDesignerProps {
  selectedOutputIndex: number;
}

export const ControlSdkOutputEditor = (props: IControlSdkOutputEditorProps) => {
  const { control, controlSdk, selectedOutputIndex, editConfigItem } = props;
  return (
    <div className={classNames.panelContent}>
      <ConfigItemEditForm
        fields={getControlSdkOutputFields(control, controlSdk, selectedOutputIndex)}
        configItem={control}
        configItemPropName="controlSdkSettings.Outputs"
        configItemArrayPropIndex={selectedOutputIndex}
        editConfigItem={editConfigItem}
      />
    </div>
  );
};

export default ControlSdkOutputEditor;
