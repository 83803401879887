import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { PivotItem } from "@fluentui/react/lib/Pivot";
import { defaultControl, getGeneralFields, getOutputFields } from "./MyControlEdit.helper";
import ConfigItemEditForm from "../common/ConfigItemEditForm";
import ConfigItemEdit, {
  IConfigItemEditProps,
  IConfigItemEditDispatchProps,
  mapStateToProps as mapStateToPropsBase,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemEdit";
import classNames from "../common/ConfigItemEdit.module.scss";
import { IState } from "../../../reducers/interfaces";
import { IIcMConnector, IEntityView } from "../../common/interfaces";
import { ConfigItemType, fetchControlSdk } from "../../../api/radarApi";
import TestDataSourceModal from "../common/TestDataSourceModal";
import { IField } from "../../../shared/components/DataForm";
import { AppContext, IAppState } from "../../../app/App";
import { controlType } from "../../controls/HealthReport.helper";
import ControlSdkDesigner from "./ControlSdkDesigner";
import { isGlobalEnvironment } from "../../../api/apiBase";
import { getEnvironmentConfigTypeSettings } from "../common/ConfigItemEdit.helper";

export interface IMyControlEditProps {
  appState?: IAppState;
}

export interface IMyControlEditStateProps extends IConfigItemEditProps {
  icmConnectors?: IIcMConnector[];
  entityViews?: IEntityView[];
  serviceContentConfigItems?: any[];
  controlMetadata?: any;
}

export interface IMyControlEditDispatchProps extends IConfigItemEditDispatchProps {
  loadIcmConnectors?: (refresh?: boolean, doNotUpdateConfigValue?: boolean) => void;
  loadEntityViews?: () => void;
  loadServiceContentConfigItems?: (refresh?: boolean) => void;
  loadControlMetadata?: (refresh?: boolean) => void;
}

export const MyControlEdit = (props: IMyControlEditProps & IMyControlEditDispatchProps & IMyControlEditStateProps) => {
  const generalEditForm: React.RefObject<any> = React.createRef();
  const outputEditForm: React.RefObject<any> = React.createRef();

  const {
    configValue,
    icmConnectors,
    entityViews,
    serviceContentConfigItems,
    controlMetadata,
    editConfigItem,
    loadIcmConnectors,
    loadEntityViews,
    loadServiceContentConfigItems,
    loadControlMetadata,
  } = props;

  const [showTestResultDialog, setShowTestResultDialog] = useState<boolean>(false);
  const [testDataSource, setTestDataSource] = useState<object>(undefined);
  const [controlSdk, setControlSdk] = useState<object>(undefined);
  const { appState } = useContext(AppContext);
  const { environments, selectedEnvironment } = appState || {};

  const isGlobal = isGlobalEnvironment(selectedEnvironment);
  const environmentSettings = getEnvironmentConfigTypeSettings(environments, ConfigItemType.Controls);
  const environmentFields = !isGlobalEnvironment(selectedEnvironment) && environmentSettings?.fields;

  const getFieldErrors = () => {
    var generalEditFormErrors = generalEditForm.current?.getErrors();
    var outputEditFormErrors = outputEditForm.current?.getErrors();

    return { ...generalEditFormErrors, ...outputEditFormErrors };
  };

  useEffect(() => {
    if (!icmConnectors?.length) {
      loadIcmConnectors(true, true);
    }
  }, [icmConnectors?.length, loadIcmConnectors]);

  useEffect(() => {
    if (!controlMetadata) {
      loadControlMetadata();
    }
  }, [controlMetadata, loadControlMetadata]);

  useEffect(() => {
    if (!entityViews?.length) {
      loadEntityViews();
    }
  }, [entityViews?.length, loadEntityViews]);

  useEffect(() => {
    if (!serviceContentConfigItems?.length) {
      loadServiceContentConfigItems();
    }
  }, [serviceContentConfigItems?.length, loadServiceContentConfigItems]);

  useEffect(() => {
    fetchControlSdk().then((controlSdk) => setControlSdk(controlSdk));
  }, []);

  let configItem = null,
    entityTypes = [];

  entityViews &&
    entityViews.forEach((view) => {
      if (view.nodes && view.nodes.length) {
        view.nodes.forEach((node) => {
          entityTypes.indexOf(node.id) < 0 && entityTypes.push(node.id);
        });
      }
    });

  try {
    configItem = JSON.parse(configValue);
  } catch {}

  if (!configItem) {
    configItem = {};
  }

  if (isGlobal) {
    if (!configItem.outputConfig) {
      configItem.outputConfig = {};
    }

    if (!Array.isArray(configItem.icmSettings)) {
      configItem.icmSettings = [
        {
          autoIncidentOnFailure: configItem.autoIncidentOnFailure,
          icmConnectorId: configItem.icmConnectorId,
          icmTeamPublicId: configItem.icmTeamPublicId,
          icmRoutingId: configItem.icmRoutingId,
          icmEnvironment: configItem.icmEnvironment,
          icmKeywords: configItem.icmKeywords,
          icmIncidentType: configItem.icmIncidentType,
          defaultIncidentSeverity: configItem.defaultIncidentSeverity,
          maxIcMFailLineItem: configItem.maxIcMFailLineItem,
          newIncidentOnFailure: configItem.newIncidentOnFailure,
          useNameAsTitle: configItem.useNameAsTitle,
          icmTitleFieldName: configItem.icmTitleFieldName,
          icmSummaryFieldNames: configItem.icmSummaryFieldNames,
          icmTsgId: configItem.icmTsgId,
          icmTsgOutput: configItem.icmTsgOutput,
          icmTags: configItem.icmTags,
          customFields: configItem.customFields,
          createIcMOnLineItemLevel: configItem.createIcMOnLineItemLevel,
          lineItemIdFieldName: configItem.lineItemIdFieldName,
          lineItemTitleFieldName: configItem.lineItemTitleFieldName,
          maxIcMIncidents: configItem.maxIcMIncidents,
        },
      ];
    }
  }

  const onTestDataSource = (dataSource, field: IField) => {
    if (field?.fieldProps && field.fieldProps["queryFieldName"]) {
      dataSource.query = dataSource[field.fieldProps["queryFieldName"]];
    }

    setTestDataSource(dataSource);
    setShowTestResultDialog(true);
  };

  const pivotItems = [
    <PivotItem key="general" itemKey="general" className={classNames.pivotItem} headerText="General">
      <ConfigItemEditForm
        configItem={configItem}
        fields={getGeneralFields(
          configItem,
          icmConnectors,
          entityTypes,
          serviceContentConfigItems,
          controlMetadata,
          controlSdk,
          onTestDataSource
        )}
        editConfigItem={editConfigItem}
        environmentFields={environmentFields}
        ref={generalEditForm}
      />
    </PivotItem>,
  ];

  if (configItem["type"] === controlType.SDK) {
    pivotItems.push(
      <PivotItem key="designer" itemKey="designer" className={classNames.pivotItem} headerText="Designer">
        <ControlSdkDesigner control={configItem} controlSdk={controlSdk} editConfigItem={editConfigItem} />
      </PivotItem>
    );
  }

  let outputEnvironmentFields =
    environmentFields?.find && environmentFields.find((envField) => envField.fieldName === "outputConfig")?.fields;

  pivotItems.push(
    <PivotItem key="output" itemKey="output" className={classNames.pivotItem} headerText="Output">
      <ConfigItemEditForm
        configItem={configItem}
        fields={getOutputFields(configItem?.outputConfig)}
        editConfigItem={editConfigItem}
        configItemPropName="outputConfig"
        environmentFields={Array.isArray(outputEnvironmentFields) && outputEnvironmentFields}
        ref={outputEditForm}
      />
    </PivotItem>
  );

  return (
    <>
      <ConfigItemEdit
        {...props}
        configItem={configItem}
        configItemName="Control"
        configItemType={ConfigItemType.Controls}
        appInsightsPageName="MyControlEditPage"
        leftNavUrl={leftNavUrls.management.control}
        defaultConfigItem={defaultControl}
        getFieldErrors={getFieldErrors}
        pivotItems={pivotItems}
      />
      <TestDataSourceModal
        hidden={!showTestResultDialog}
        dataSource={testDataSource}
        onDismiss={() => setShowTestResultDialog(false)}
      />
    </>
  );
};

const mapStateToProps = (state: IState) => ({
  ...mapStateToPropsBase(state),
  icmConnectors: state.modules.icm_connectors,
  entityViews: state.modules.entity_views,
  serviceContentConfigItems: state.modules.service_content_configs,
  controlMetadata: state.modules.control_metadata,
  loading: state.modules.loading_config || state.modules.loading_controls || state.modules.loading_control_results,
});

const mapDispatchToProps: IMyControlEditDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItem: actionCreator.loadControlConfig,
  updateConfigItem: actionCreator.updateControl,
  loadIcmConnectors: actionCreator.loadIcmConnectors,
  loadEntityViews: actionCreator.loadEntityViewConfigItems,
  loadServiceContentConfigItems: actionCreator.loadServiceContentConfigItems,
  loadControlMetadata: actionCreator.loadControlMetadata,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyControlEdit);
