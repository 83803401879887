import React from "react";
import { DataForm, FieldType, IField } from "../../../shared/components/DataForm";
import { getPropValueByPath } from "../../../shared/utilities/miscHelper";

export interface IConfigItemEditFormProps {
  configItem: any;
  fields: IField[];
  configItemPropName?: string;
  configItemArrayPropIndex?: number;
  environmentFields?: IField[];
  editConfigItem: (configValue: string) => void;
  onFormItemSelected?: (fieldName: string, value: any) => void;
}

export class ConfigItemEditForm extends React.Component<IConfigItemEditFormProps> {
  private dataForm: React.RefObject<any> = React.createRef();

  render() {
    const { configItem, fields, environmentFields, onFormItemSelected } = this.props;

    if (!configItem) return null;

    if (environmentFields?.length) {
      this.updateFieldsVisibilityBasedOnEnvironment(fields, environmentFields);
    }

    return (
      <DataForm
        fields={fields}
        onFieldValueChange={this.onFieldValueChange}
        onItemSelected={onFormItemSelected}
        ref={this.dataForm}
        context={configItem}
      />
    );
  }

  onFieldValueChange = (fieldName: string, newValue: any) => {
    const { configItem, editConfigItem, configItemPropName: propName, configItemArrayPropIndex } = this.props;

    if (configItem) {
      let configObject = propName ? getPropValueByPath(configItem, propName, configItemArrayPropIndex) : configItem;

      if (newValue === "" || newValue === null || newValue === undefined) {
        delete configObject[fieldName];
      } else if (
        typeof newValue === "object" &&
        typeof configObject === "object" &&
        typeof configObject[fieldName] === "object"
      ) {
        configObject[fieldName] = { ...configObject[fieldName], ...newValue };
      } else {
        configObject[fieldName] = newValue;
      }

      editConfigItem && editConfigItem(JSON.stringify(configItem, null, 2));
    }
  };

  getErrors = () => this.dataForm && this.dataForm.current && this.dataForm.current.getErrors();

  updateFieldsVisibilityBasedOnEnvironment = (fields: IField[], environmentFields: IField[]) => {
    // Walk through the field hierarchy and set the field visibility based on the environment fields.
    fields?.forEach((field) => {
      if (field.fieldName !== "id") {
        // Skip id field.
        let environmentField = environmentFields.find(
          (environmentField) => environmentField.fieldName === field.fieldName
        );

        // For non-matching field, set field visibility to false.
        if (!environmentField) {
          field.visible = false;
        } else {
          // For matching field, merge all environment settings into the field.
          Object.keys(environmentField).forEach((envFieldPropName) => {
            if (envFieldPropName !== "fields") {
              let fieldValue = environmentField[envFieldPropName];

              if (envFieldPropName === "fieldType") {
                fieldValue = FieldType[fieldValue as keyof typeof FieldType];
              }

              field[envFieldPropName] = fieldValue;
            }
          });
        }

        if (field.fields?.length && Array.isArray(field.fields)) {
          if (field.fieldType === FieldType.container) {
            this.updateFieldsVisibilityBasedOnEnvironment(field.fields, environmentFields);
          } else if (environmentField?.fields?.length && Array.isArray(environmentField.fields))
            this.updateFieldsVisibilityBasedOnEnvironment(field.fields, environmentField.fields);
        }
      }
    });
  };
}

export default ConfigItemEditForm;
