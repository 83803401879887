import React from "react";
import { getControlSdkRuntimeFields } from "./MyControlEdit.helper";
import classNames from "./ControlSdkDesigner.module.scss";
import ConfigItemEditForm from "../common/ConfigItemEditForm";
import { IControlSdkDesignerProps } from "./ControlSdkDesigner";

export const ControlSdkRuntimeEditor = (props: IControlSdkDesignerProps) => {
  const { control, controlSdk, editConfigItem } = props;
  return (
    <div className={classNames.panelContent}>
      <ConfigItemEditForm
        fields={getControlSdkRuntimeFields(control, controlSdk)}
        configItem={control}
        configItemPropName="controlSdkSettings.Runtime"
        editConfigItem={editConfigItem}
      />
    </div>
  );
};

export default ControlSdkRuntimeEditor;
