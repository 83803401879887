import React from "react";
import { ContactLink } from "../components/ContactLink";
import * as jsonPath from "jsonpath";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { IDataContext, IField } from "../components/DataForm";

export const renderStatusIcon = (statusIcon: string, statusColor: string) => (
  <i className={`status statusIcon ms-Icon ms-Icon--${statusIcon}`} style={{ color: statusColor }} />
);

export enum statusType {
  fail,
  pass,
  warning,
}

export const getStatus = (status: string | boolean | number): statusType => {
  let statusString = status && status.toString().toLowerCase();
  if (statusString === "warning" || statusString === "timeout") return statusType.warning;
  else if (statusString === "yes" || statusString === "pass" || status === true || status === 1) return statusType.pass;
  else return statusType.fail;
};

export interface IStatusProps {
  icon: string;
  color: string;
  text: string;
}

export const getStatusProps = (
  status: string | boolean | number,
  passStatusColor: string = "green",
  failStatusColor: string = "red",
  warningStatusColor: string = "orange"
): IStatusProps => {
  if (status === undefined) {
    return {
      icon: "UnknownSolid",
      color: "grey",
      text: "N/A",
    };
  }

  let statusFlag = getStatus(status);
  let statusProps;
  switch (statusFlag) {
    case statusType.warning: {
      statusProps = {
        icon: "Warning",
        color: warningStatusColor,
        text: "Warning",
      };
      break;
    }
    case statusType.pass: {
      statusProps = {
        icon: "SkypeCircleCheck",
        color: passStatusColor,
        text: "Pass",
      };
      break;
    }
    default:
      statusProps = {
        icon: "SkypeCircleMinus",
        color: failStatusColor,
        text: "Fail",
      };
      break;
  }
  return statusProps;
};

export const renderStatus = (
  status: string | boolean | number,
  passStatusColor: string = "green",
  failStatusColor: string = "red",
  warningStatusColor: string = "orange"
) => {
  const { icon, color } = getStatusProps(status, passStatusColor, failStatusColor, warningStatusColor);

  if (status === undefined) {
    return renderStatusIcon(icon, color);
  }

  let statusText = status && status.toString().toLowerCase();

  if (
    statusText !== "yes" &&
    statusText !== "no" &&
    statusText !== "pass" &&
    statusText !== "fail" &&
    statusText !== "warning" &&
    statusText !== "timeout" &&
    status !== 1 &&
    status !== 0 &&
    typeof status !== "boolean"
  ) {
    return <span className="status">{status}</span>;
  }

  return renderStatusIcon(icon, color);
};

export const templateFormat = (template: string, variables: object): string => {
  try {
    let finalVariables = {
      ...variables,
      convertUtcToLocalTime,
      getShortNumber,
      getShortCurrency,
      getMonthText,
      getShortMonthText,
      getTimeInMinsText,
      getRelativeTime,
      getFormatContent,
      startCase,
    };

    // TODO: Evaluate is this the right way to do this if eslint flags it?
    // eslint-disable-next-line no-new-func
    return new Function("return `" + template + "`;").call(finalVariables);
  } catch (error: any) {
    return error && error.message;
  }
};

export const getValueOrDefault = (value, defaultValue, convertFalseToString = false) => {
  if (value === undefined || value === "") {
    return defaultValue;
  } else if (convertFalseToString && value === false) {
    return "false";
  } else {
    return value;
  }
};

export const evalCondition = (condition: string | boolean | ((arg) => boolean), object: object, arg?: any): boolean => {
  try {
    if (condition === undefined) return undefined;

    if (typeof condition === "boolean") {
      return condition === true;
    }
    if (typeof condition === "function") {
      return condition(arg);
    }
    // TODO: Evaluate is this the right way to do this if eslint flags it?
    // eslint-disable-next-line no-new-func
    return new Function("return `${" + condition + "}` === 'true';").call(object);
  } catch {
    return undefined;
  }
};

export enum ContentDisplayType {
  contact = "contact",
  currency = "currency",
  date = "date",
  datetime = "datetime",
  datetimeInt = "datetimeInt",
  datetimeWithRelativeTime = "datetimeWithRelativeTime",
  dropdown = "dropdown",
  html = "html",
  json = "json",
  jsonFormatted = "jsonFormatted",
  month = "month",
  number = "number",
  percentage = "percentage",
  relativeTime = "relativeTime",
  shortCurrency = "shortCurrency",
  shortDate = "shortDate",
  shortDateHour = "shortDateHour",
  shortDateTime = "shortDateTime",
  shortNumber = "shortNumber",
  status = "status",
  statusText = "statusText",
  text = "text",
  timeSpan = "timeSpan",
}

export const getFormatContent = (
  item: any,
  displayType?: ContentDisplayType,
  fieldName?: string,
  template?: string,
  passStatusColor?: string,
  failStatusColor?: string,
  warningStatusColor?: string,
  locale?: string,
  localeFieldName: string = "locale",
  currency?: string,
  currencyFieldName: string = "currency",
  decimal?: number,
  decimalFieldName: string = "decimal",
  contentProps?: any
) => {
  try {
    // Support a single parameter as props for all fixed parameters.
    if (typeof item === "object" && item.hasOwnProperty("item")) {
      displayType = displayType || item["displayType"];
      fieldName = fieldName || item["fieldName"];
      template = template || item["template"];
      passStatusColor = passStatusColor || item["passStatusColor"];
      failStatusColor = failStatusColor || item["failStatusColor"];
      warningStatusColor = warningStatusColor || item["warningStatusColor"];
      locale = locale || item["locale"];
      localeFieldName = localeFieldName || item["localeFieldName"];
      currency = currency || item["currency"];
      currencyFieldName = currencyFieldName || item["currencyFieldName"];
      decimal = decimal || item["decimal"];
      decimalFieldName = decimalFieldName || item["decimalFieldName"];
      contentProps = contentProps || item["contentProps"];
      item = item["item"];
    }

    if (Array.isArray(item)) {
      item = item.toString().replace(/,/g, ", ");
    }

    if (React.isValidElement(item)) {
      return item;
    }

    let value =
        typeof item === "object" && item !== "undefined"
          ? template
            ? templateFormat(template, item)
            : getItemField(item, fieldName)
          : item,
      itemLocale = locale || getItemField(item, localeFieldName) || "en-us",
      itemCurrency = currency || getItemField(item, currencyFieldName) || "USD",
      itemDecimalFieldValue = getItemField(item, decimalFieldName),
      itemDecimal = decimal !== undefined ? decimal : itemDecimalFieldValue !== undefined ? itemDecimalFieldValue : 2;

    value = checkForValue(value) ? value : contentProps?.defaultValue;

    if (displayType === ContentDisplayType.html) {
      return <div dangerouslySetInnerHTML={{ __html: value }} />;
    } else if (displayType === ContentDisplayType.status) {
      return renderStatus(value, passStatusColor, failStatusColor, warningStatusColor);
    } else if (displayType === ContentDisplayType.statusText) {
      return <div>{renderStatus(value, passStatusColor, failStatusColor, warningStatusColor)}</div>;
    } else if (displayType === ContentDisplayType.date) {
      return value && new Date(value).toLocaleDateString(itemLocale);
    } else if (displayType === ContentDisplayType.datetime) {
      return value && new Date(value).toLocaleString(itemLocale);
    } else if (displayType === ContentDisplayType.datetimeInt) {
      let d = value && value.toString(),
        datetimeText = `${d[0]}${d[1]}${d[2]}${d[3]}-${d[4]}${d[5]}-${d[6]}${d[7]}T${d[8]}${d[9]}:${d[10]}${d[11]}:${d[12]}${d[13]}Z`;
      return new Date(datetimeText).toLocaleString(itemLocale);
    } else if (displayType === ContentDisplayType.month) {
      return value && new Date(value).toLocaleString(itemLocale, { month: "short", year: "numeric" });
    } else if (displayType === ContentDisplayType.currency) {
      return Number(value).toLocaleString(itemLocale, {
        style: "currency",
        currency: itemCurrency,
        minimumFractionDigits: itemDecimal,
        maximumFractionDigits: itemDecimal,
      });
    } else if (displayType === ContentDisplayType.percentage) {
      const powerFactor = Math.pow(10, itemDecimal);
      return (Math.round(Number(value) * powerFactor) / powerFactor).toFixed(itemDecimal) + "%";
    } else if (displayType === ContentDisplayType.number) {
      return Number(Number(value).toFixed(itemDecimal)).toLocaleString(itemLocale);
    } else if (displayType === ContentDisplayType.shortCurrency) {
      return getShortCurrency(Number(value), itemLocale, itemCurrency, itemDecimal);
    } else if (displayType === ContentDisplayType.shortDate) {
      let date = value && new Date(value);
      return getShortDateText(date);
    } else if (displayType === ContentDisplayType.shortDateHour) {
      let date = value && new Date(value),
        dateString = date.toISOString();
      return `${getShortDateText(date)} ${dateString?.slice(11, 13)}h`;
    } else if (displayType === ContentDisplayType.shortDateTime) {
      let date = value && new Date(value),
        dateString = date.toISOString();
      return `${getShortDateText(date)} ${dateString?.slice(11, 16)}`;
    } else if (displayType === ContentDisplayType.shortNumber) {
      return getShortNumber(Number(value), itemLocale, itemDecimal);
    } else if (displayType === ContentDisplayType.relativeTime) {
      return getRelativeTime(value, itemLocale);
    } else if (displayType === ContentDisplayType.datetimeWithRelativeTime) {
      return getFullDateWithShortRelativeTime(value, itemLocale);
    } else if (displayType === ContentDisplayType.timeSpan) {
      return getTimeInMinsText(getTimeInMins(new Date(), new Date(value)));
    } else if (displayType === ContentDisplayType.contact) {
      return (
        <ContactLink
          alias={value}
          messageSubject={item?.contactMessageSubject}
          messageBody={item?.contactMessageBody}
        />
      );
    } else if (displayType === ContentDisplayType.json) {
      return getJsonText(value);
    } else if (displayType === ContentDisplayType.jsonFormatted) {
      return getJsonFormattedText(value);
    } else if (displayType === ContentDisplayType.dropdown && contentProps?.options) {
      return getDropdown(fieldName, value, contentProps);
    } else if (value === undefined || value === null) {
      return "";
    } else if (typeof value === "object") {
      let valueString = value.toString && value.toString();
      try {
        valueString = JSON.stringify(value);
      } catch {}
      return valueString;
    }

    return value;
  } catch (error: any) {
    return error && error.message;
  }
};

export const getUniqueValues = (arr: string[], sortArray?: boolean) => {
  var mySet = new Set(arr);
  var filteredArray = Array.from(mySet);
  return sortArray ? filteredArray.sort() : filteredArray;
};

/**
 * This is a special debounce that you call the first time to get a wrapped function and save that function.
 * Then when you want to call the actual debounced function you call that saved one.
 * This way it fully supports arguments.
 */
export const getDebounceMethod = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const getItemField = (item: any, fieldName: string) => {
  let camelCaseFieldName = camelCase(fieldName),
    result =
      item?.hasOwnProperty &&
      (item.hasOwnProperty(fieldName)
        ? item[fieldName]
        : item.hasOwnProperty(camelCaseFieldName)
        ? item[camelCaseFieldName]
        : typeof item === "object"
        ? jsonPath.query(item, fieldName)
        : undefined);

  if (Array.isArray(result)) {
    result = result.length > 0 ? result[0] : undefined;
  }
  return result && result.trim ? result.trim() : result;
};

export const camelCase = (text: string): string => {
  if (!text || !text.length) return text;
  return text[0].toLowerCase() + text.substr(1);
};

export const pascalCase = (text: string): string => {
  if (!text || !text.length) return text;
  return text[0].toUpperCase() + text.slice(1);
};

export const startCase = (text: string): string => {
  let result = text?.replace(/([A-Z])/g, " $1");
  return result?.charAt(0).toUpperCase() + result?.slice(1).replace(/([A-Z])\s(?=[A-Z])/g, "$1");
};

let numberRanges = [
  { divider: 1e12, suffix: "T" },
  { divider: 1e9, suffix: "B" },
  { divider: 1e6, suffix: "M" },
  { divider: 1e3, suffix: "k" },
];

export const getShortNumber = (n: number, locale: string = "en-us", decimal: number = 2): string => {
  let sign = n < 0 ? "-" : "",
    decimalFactor = Math.pow(10, decimal);

  n = Math.abs(n);

  for (var i = 0; i < numberRanges.length; i++) {
    if (n >= numberRanges[i].divider) {
      let value = Math.round((n / numberRanges[i].divider) * decimalFactor) / decimalFactor;
      return sign + value.toLocaleString(locale) + numberRanges[i].suffix;
    }
  }
  return sign + n.toLocaleString(locale);
};

export const getShortCurrency = (
  n: number,
  locale: string = "en-us",
  currency: string = "USD",
  decimal: number = 2
): string => {
  let sign = n < 0 ? "-" : "",
    decimalFactor = Math.pow(10, decimal);

  n = Math.abs(n);

  for (var i = 0; i < numberRanges.length; i++) {
    if (n >= numberRanges[i].divider) {
      let value = Math.round((n / numberRanges[i].divider) * decimalFactor) / decimalFactor;
      return (
        sign +
        value.toLocaleString(locale, {
          style: "currency",
          currency,
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal,
        }) +
        numberRanges[i].suffix
      );
    }
  }
  return (
    sign +
    n.toLocaleString(locale, {
      style: "currency",
      currency,
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    })
  );
};

export const getRelativeTime = (value: string, locale: string = "en-us"): string => {
  let targetTime = new Date(value).valueOf(),
    currentTime = new Date().valueOf(),
    timeDiff = currentTime - targetTime;

  if (timeDiff < 60000) {
    return "now";
  } else if (timeDiff < 3600000) {
    let mins = Math.round(timeDiff / 60000);
    return mins + " min" + (mins > 1 ? "s" : "") + " ago";
  } else if (timeDiff < 86400000) {
    let hours = Math.round(timeDiff / 3600000);
    return hours + " hour" + (hours > 1 ? "s" : "") + " ago";
  } else if (timeDiff < 2592000000) {
    let days = Math.round(timeDiff / 86400000);
    return days + " day" + (days > 1 ? "s" : "") + " ago";
  }

  return new Date(value).toLocaleString(locale);
};

export const getFullDateWithShortRelativeTime = (value: string, locale: string = "en-us"): string => {
  let targetTime = new Date(value).valueOf(),
    currentTime = new Date().valueOf(),
    timeDiff = currentTime - targetTime,
    shortRelativeTime = undefined;

  if (timeDiff < 60000) {
    shortRelativeTime = "now";
  } else if (timeDiff < 86400000) {
    let hours = Math.round(timeDiff / 3600000);
    shortRelativeTime = hours + "h";
  } else {
    let days = Math.round(timeDiff / 86400000);
    shortRelativeTime = days + "d";
  }

  return new Date(value).toLocaleString(locale) + ` (${shortRelativeTime})`;
};

export const getTimeInMins = (startTime: Date, endTime: Date): number =>
  (endTime.valueOf() - startTime.valueOf()) / 60000;

export const getTimeInMinsText = (timeInMins: number): string => {
  let result = "",
    days = Math.floor(timeInMins / 1440),
    hours = Math.floor((timeInMins - days * 1440) / 60),
    minutes = Math.floor(timeInMins % 60);

  if (days > 0) {
    result = days + " day" + (days > 1 ? "s " : " ");
  }

  if (hours > 0) {
    result += hours + " hr" + (hours > 1 ? "s " : " ");
  }

  if (minutes > 0) {
    result += minutes + " min" + (minutes > 1 ? "s" : "");
  }
  return result;
};

export const getDateFromDayDiff = (dayDiff: number): Date => {
  var date = new Date();

  date.setDate(date.getDate() + dayDiff);

  return date;
};

export const longMonthTexts = [
  "December",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const shortMonthTexts = [
  "Dec",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getMonthText = (month: number): string => month <= 12 && longMonthTexts[month];

export const getShortMonthText = (month: number): string => month <= 12 && shortMonthTexts[month];

export const getJsonText = (value) => {
  try {
    if (typeof value === "string") {
      return JSON.stringify(JSON.parse(value), null, 2);
    } else {
      return JSON.stringify(value, null, 2);
    }
  } catch {
    return value;
  }
};

export const parseJsonSafe = (value: string) => {
  try {
    return JSON.parse(value);
  } catch {
    return null;
  }
};

export const getJsonFormattedText = (value) => {
  return <pre>{getJsonText(value)}</pre>;
};

export const getUtcDateString = (date: Date = new Date()): string => {
  return date.toISOString().substring(0, 10) + "T08:00:00.000Z";
};

export const getUtcDateStringFromString = (dateString: string): string => {
  return getUtcDateString(new Date(dateString));
};

export const getDateTimeISOString = (date: Date, monthValueDiff: number = 0, endTime: boolean = false): string => {
  if (!date) return null;

  let result = new Date(date);

  if (monthValueDiff) {
    result = endTime ? getLastOfMonth(monthValueDiff, result) : getFirstOfMonth(monthValueDiff, result);
  }

  return result.toISOString();
};

export const getFirstOfMonthISODate = (monthValueDiff: number = 0, date: Date = new Date()): string =>
  getFirstOfMonth(monthValueDiff, date).toISOString().substring(0, 10);

export const getLastOfMonthISODate = (monthValueDiff: number = 0, date: Date = new Date()): string =>
  getLastOfMonth(monthValueDiff, date).toISOString().substring(0, 10);

export const getPlainText = (htmlText: string): string => {
  let div = document.createElement("div");
  div.innerHTML = htmlText;

  let plainText = div.innerText;

  div.remove();

  return plainText;
};

export const getDateTime = (dayDiff: number = 0, setEndOfDayTime = false): Date => {
  var result = new Date();

  if (dayDiff !== 0) {
    result = new Date(result.setDate(result.getDate() + dayDiff));
  }

  if (setEndOfDayTime) {
    result.setHours(23, 59, 59, 999);
  } else {
    result.setHours(0, 0, 0, 0);
  }

  return result;
};

export const getFirstOfMonth = (monthDiff: number = 0, date: Date = new Date()): Date => {
  var result = new Date(date);

  if (monthDiff !== 0) {
    result = new Date(result.setUTCMonth(result.getUTCMonth() + monthDiff));
  }

  result.setUTCDate(1);
  result.setUTCHours(0, 0, 0, 0);

  return result;
};

export const getLastOfMonth = (monthDiff: number = 0, date: Date = new Date()): Date => {
  var result = new Date(date);

  result = new Date(result.setUTCMonth(result.getUTCMonth() + monthDiff + 1));

  result.setUTCDate(0);
  result.setUTCHours(23, 59, 59, 999);

  return result;
};

export const getShortDateText = (date: Date): string => date?.getMonth() + 1 + "/" + ("0" + date.getDate()).slice(-2);

export const copyToClipboard = (value: string) => {
  function listener(e) {
    var plainTextValue = getPlainText(value);
    e.clipboardData.setData("text/html", value);
    e.clipboardData.setData("text/plain", plainTextValue);
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
};

/**
 * Compares two Date objects and returns a number value that represents
 * the result:
 * 0 if the two dates are equal.
 * 1 if the first date is greater than second.
 * -1 if the first date is less than second.
 * Also allows for passing offsets to correct for timezone differences and rounding.
 * Original source from: https://expertcodeblog.wordpress.com/2018/03/12/typescript-how-to-compare-two-dates/
 * @param date1 First date object to compare.
 * @param date2 Second date object to compare.
 * @param date1OffsetInHours [Optional] Offset in hours to be added to date1
 * @param date2OffsetInHours [Optional] Offset in hours to be added to date2
 * @param roundToNearestSecond [Default: True] Round down to nearest second
 */
export const compareDates = (
  date1: string | Date,
  date2: string | Date,
  date1OffsetInHours?: number,
  date2OffsetInHours?: number,
  roundToNearestSecond: boolean = true
): number => {
  // With Date object we can compare dates them using the >, <, <= or >=.
  // The ==, !=, ===, and !== operators require to use date.getTime(),
  // so we need to create a new instance of Date with 'new Date()'
  let d1 = new Date(date1);
  let d2 = new Date(date2);

  if (date1OffsetInHours) {
    d1.setHours(d1.getHours() + date1OffsetInHours);
  }
  if (date2OffsetInHours) {
    d2.setHours(d2.getHours() + date2OffsetInHours);
  }

  if (roundToNearestSecond) {
    // Zeroing out the milliseconds to account for rounding errors with date storage
    d1.setMilliseconds(0);
    d2.setMilliseconds(0);
  }

  // Check if the dates are equal
  let same = d1.getTime() === d2.getTime();
  if (same) return 0;

  // Check if the first is greater than second
  if (d1 > d2) return 1;

  // Check if the first is less than second
  if (d1 < d2) return -1;
};

export const caseInsensitiveCompare = (a, b) => {
  return (
    typeof a === "string" && typeof b === "string" && a.localeCompare(b, undefined, { sensitivity: "accent" }) === 0
  );
};

export const checkForValue = (node) => node !== null && node !== undefined && node !== "";

export const getDropdown = (fieldName: string, value, contentProps) => {
  const onChange = (ev, option: IDropdownOption) => {
    contentProps?.onColumnValueSelect && contentProps?.onColumnValueSelect(fieldName, option?.key);
  };

  return <Dropdown {...contentProps} style={{ width: contentProps.width }} selectedKey={value} onChange={onChange} />;
};

export const convertUtcToLocalTime = (utcTime: string): string => new Date(utcTime + " UTC").toLocaleString();

export const getCookie = (cname: string): string => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const isObjectEqual = (previous: any, current: any): boolean => {
  return Object.keys(current).every((key) => {
    return isEqual(previous[key], current[key]);
  });
};

export const isArrayEqual = (prevArray?: any[], currentArray?: any[]): boolean => {
  if (
    (!checkForValue(currentArray) && checkForValue(prevArray)) ||
    (checkForValue(currentArray) && !checkForValue(prevArray)) ||
    prevArray.length !== currentArray.length
  ) {
    return false;
  }
  for (let index = 0; index < currentArray.length; index++) {
    const previous = prevArray[index];
    const current = currentArray[index];
    if (!isEqual(previous, current)) {
      return false;
    }
  }
  return true;
};

export const isEqual = (previous: any, current: any): boolean => {
  if (!checkForValue(current) && checkForValue(previous)) {
    return false;
  } else if (!checkForValue(current) && !checkForValue(previous)) {
    return true;
  } else if (Array.isArray(current)) {
    return isArrayEqual(previous, current);
  } else if (typeof current === "object") {
    return isObjectEqual(previous, current);
  } else if (previous !== current) {
    return false;
  }
  return true;
};

// This is to trim the Base64 images so they don't show up while viewing and diffing revisions
export const trimBase64Source = (config: string) => {
  const START_STRING = '<img src=\\"data:image/png;base64,';
  const START_STRING_LENGTH = START_STRING.length + 1;
  const SHOW_CHARS_OF_BASE64_IMAGE = START_STRING_LENGTH + 20;
  const ALT_END_STRING = "alt=";
  const END_STRING = ">";

  if (!config) {
    return null;
  }

  let startIndex = config.indexOf(START_STRING, 0);
  let endIndex = config.indexOf(ALT_END_STRING, startIndex + START_STRING_LENGTH);
  if (endIndex === -1) {
    endIndex = config.indexOf(END_STRING, startIndex + START_STRING_LENGTH);
  }
  let newConfig = config;
  while (startIndex > -1 && endIndex > -1) {
    const imgText = newConfig.substring(startIndex, endIndex);
    newConfig =
      newConfig.substring(0, startIndex) +
      imgText.substring(0, SHOW_CHARS_OF_BASE64_IMAGE) +
      "..." +
      newConfig.substring(endIndex + -1);
    startIndex = config.indexOf(START_STRING, endIndex + 1);
    endIndex = config.indexOf(ALT_END_STRING, endIndex + 1);
    if (endIndex === -1) {
      endIndex = config.indexOf(END_STRING, startIndex + START_STRING_LENGTH);
    }
  }
  return newConfig;
};

export const textContent = (element: React.ReactElement | string): string => {
  if (!element) {
    return "";
  }

  if (typeof element === "string") {
    return element;
  }

  const children = element.props && element.props.children;

  if (children instanceof Array) {
    return children.map(textContent).join("");
  }

  return textContent(children);
};

export function getDynamicFieldValue<Type>(field: IField, propName: string, dataContext: IDataContext): Type {
  let prop = field[propName];

  let value = typeof prop === "function" ? prop(dataContext) : prop;
  field[propName] = value;

  return value;
}

export function getPropValueByPath(item: object, path: string, arrayIndex?: number): any {
  let props = path?.split("."),
    result = item;

  if (props?.length) {
    for (let i = 0; i < props.length; i++) {
      if (result == null) {
        return undefined;
      }

      result = result[props[i]];

      if (typeof result !== "object") {
        return result;
      } else if (Array.isArray(result) && arrayIndex !== undefined) {
        return result[arrayIndex];
      }
    }
  }

  return result;
}
