import React from "react";
import { getControlSdkInputFields } from "./MyControlEdit.helper";
import classNames from "./ControlSdkDesigner.module.scss";
import ConfigItemEditForm from "../common/ConfigItemEditForm";
import { IControlSdkDesignerProps } from "./ControlSdkDesigner";

export const ControlSdkInputEditor = (props: IControlSdkDesignerProps) => {
  const { control, controlSdk, editConfigItem } = props;
  return (
    <div className={classNames.panelContent}>
      <ConfigItemEditForm
        fields={getControlSdkInputFields(control, controlSdk)}
        configItem={control}
        configItemPropName="controlSdkSettings"
        editConfigItem={editConfigItem}
      />
    </div>
  );
};

export default ControlSdkInputEditor;
